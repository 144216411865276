<template>
  <div><treatment-list /></div>
</template>

<script>
import TreatmentList from "./components/TreatmentList/TreatmentList.vue";
export default {
  components: { TreatmentList },
};
</script>

<style></style>
