<template>
  <b-modal
    id="add-new-treatment-sidebar"
    centered
    :visible="isAddNewTreatmentSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    size="lg"
    backdrop
    no-header
    right
    :title="$t('treatmentAdd')"
    :ok-title="$t('ok')"
    ok-only
    @ok="onSubmit(blankTreatmentData)"
    @hidden="formValidation(resetblanktreatment).resetForm"
    @change="(val) => changed(val)"
  >
    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="formValidation(
      resetblanktreatment
    ).refFormObserver"
    >
      <!-- Form -->

      <b-form
        ref="refForm"
        class="p-2 modal-form"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <button ref="submitButtonRef" type="submit" class="d-none" />
        <!-- Kod -->
        <validation-provider
          #default="validationContext"
          rules="required"
          name="TreatmentName"
        >
          <b-form-group :label="$t('treatmentName')" label-for="TreatmentName">
            <b-form-input
              id="TreatmentName"
              v-model="blankTreatmentData.TreatmentName"
              autofocus
              :state="
                formValidation(resetblanktreatment).getValidationState(
                  validationContext
                )
              "
              trim
              placeholder=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          name="treatmentType"
          rules="required"
        >
          <b-form-group :label="$t('treatmentType')" label-for="type">
            <v-select
              v-model="blankTreatmentData.treatmenttypeid"
              transition=""
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="treatmentTypes"
              class="w-100"
              :reduce="(val) => val.value"
              :state="
                formValidation(resetblanktreatment).getValidationState(
                  validationContext
                )
              "
            />
          </b-form-group>
        </validation-provider>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from "bootstrap-vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewTreatmentSidebarActive",
    event: "update:is-add-new-treatment-sidebar-active",
  },
  props: {
    isAddNewTreatmentSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      blankTreatmentData: {
        treatmentName: "",
        treatmenttypeid: 0,
        companyid: getUserData().companyid,
      },
      formValidation: formValidation,
      treatmentTypes: [],
    };
  },
  async mounted() {
    var treatment = await store.dispatch(
      "definitionsModule/fetchTreatmentTypes"
    );
    this.treatmentTypes = treatment.map((x) => ({
      value: x.id,
      label: x.treatmenttypename,
    }));
  },
  methods: {
    async changed(val) {
      this.$emit("update:is-add-new-treatment-sidebar-active", val);
      this.resetblanktreatment();
    },
    onSubmit(blankTreatmentData) {
      store
        .dispatch("definitionsModule/addTreatment", blankTreatmentData)
        .then(() => {
          this.$emit("refetch-data");
          this.$emit("update:is-add-new-treatment-sidebar-active", false);
        });
    },
    async resetblanktreatment() {
      this.blankTreatmentData = {
        treatmentName: "",
        treatmenttypeid: 0,
        companyid: getUserData().companyid,
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-treatment-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
