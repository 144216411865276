import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import VueI18n from "@/libs/i18n";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useTreatmentList() {
  // Use toast
  const toast = useToast();

  const refTreatmentListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    {
      key: "treatmentName",
      label: VueI18n.t("treatmentName"),
      sortable: false,
    },
    {
      key: "treatmenttype",
      label: VueI18n.t("treatmentType"),
      sortable: false,
    },
    { key: "delete", label: VueI18n.t("delete") },
    { key: "edit", label: VueI18n.t("edit"), sortable: false },
  ];
  const perPage = ref(10);
  const totalTreatments = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);
  const allTreatments = ref([]);
  const filteredTreatments = ref([]);

  const dataMeta = computed(() => {
    const localItemsCount = filteredTreatments.value.length;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to:
        perPage.value * (currentPage.value - 1) +
          perPage.value * currentPage.value <
        localItemsCount
          ? perPage.value
          : localItemsCount,
      of: allTreatments.value.length,
    };
  });

  const refetchData = () => {
    if (searchQuery.value)
      filteredTreatments.value = allTreatments.value.filter((val) =>
        val.nutrientname.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    else filteredTreatments.value = allTreatments.value;
  };

  watch(
    [currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter],
    () => {
      refetchData();
    }
  );
  const fetchTreatments = async (ctx, callback) => {
    var treatment = await store
      .dispatch("definitionsModule/fetchTreatments")

      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("xFetchError", { value: VueI18n.t("treatments") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    allTreatments.value = treatment;
    filteredTreatments.value = [...treatment];

    return treatment;
  };
  const deleteTreatment = (ctx) => {
    store
      .dispatch("definitionsModule/deleteTreatment", ctx)
      .then((response) => {
        fetchTreatments();
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: VueI18n.t("deleted", { type: VueI18n.t("treatment") }),
          },
        });
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("unsuccessful"),
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: VueI18n.t("notDeleted", { type: VueI18n.t("treatment") }),
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchTreatments,
    tableColumns,
    perPage,
    currentPage,
    totalTreatments,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTreatmentListTable,
    deleteTreatment,
    filteredTreatments,
    allTreatments,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  };
}
