<template>
  <b-modal
    id="edit-treatment-sidebar"
    centered
    :visible="isEditTreatmentSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    size="lg"
    backdrop
    no-header
    right
    :ok-title="$t('ok')"
    ok-only
    @ok="onSubmit(blankTreatmentData)"
    @hidden="formValidation(resetblanktreatment).resetForm"
    @change="(val) => changed(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ $t("updateTreatment") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="formValidation(
      resetblanktreatment
    ).refFormObserver"
      >
        <!-- Form -->

        <b-form
          ref="refForm"
          class="p-2 modal-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <button ref="submitButtonRef" type="submit" class="d-none" />
          <!-- Kod -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="kod"
          >
            <b-form-group
              :label="$t('treatmentName')"
              label-for="treatmentname"
            >
              <b-form-input
                id="treatmentname"
                v-model="blankTreatmentData.treatmentName"
                autofocus
                :state="
                  formValidation(resetblanktreatment).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            rules="required"
            name="kod"
          >
            <b-form-group :label="$t('type')" label-for="type">
              <v-select
                v-model="blankTreatmentData.treatmenttypeid"
                transition=""
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="treatmentTypes"
                :state="
                  formValidation(resetblanktreatment).getValidationState(
                    validationContext
                  )
                "
                class="w-100"
                :reduce="(val) => val.value"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <!-- <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t("update") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("cancel") }}
            </b-button>
          </div> -->
        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditTreatmentSidebarActive",
    event: "update:is-edit-treatment-sidebar-active",
  },
  props: {
    isEditTreatmentSidebarActive: {
      type: Boolean,
      required: true,
    },
    treatment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      blankTreatmentData: { ...this.treatment },
      formValidation: formValidation,
      treatmentTypes: [],
    };
  },
  async mounted() {
    var treatment = await store.dispatch(
      "definitionsModule/fetchTreatmentTypes"
    );
    this.treatmentTypes = treatment.map((x) => ({
      value: x.id,
      label: x.treatmenttypename,
    }));
    this.resetblanktreatment();
  },
  methods: {
    async changed(val) {
      this.imgStr = "";
      this.resetblanktreatment();
      this.$emit("update:is-edit-treatment-sidebar-active", val);
    },
    onSubmit(blankTreatmentData) {
      store
        .dispatch("definitionsModule/updateTreatment", blankTreatmentData)
        .then(() => {
          this.$emit("refetch-data");
          this.$emit("update:is-edit-treatment-sidebar-active", false);
        });
    },
    async resetblanktreatment() {
      this.lankTreatmentData = { ...this.treatment };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#edit-treatment-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
